// react
import React from "react";
import {Table} from "react-bootstrap";
import {NavigateFunction} from "react-router-dom";
import {FaRegBuilding} from "react-icons/fa";

// local
import {
	firstLastToName,
	formatContactInfo,
	Client,
	CourseInfo,
	SortCourseFilter,
	User
} from "../../types";

/**
 * StudentsSummary
 */

interface SummaryProps {
	user: User,
	client: Client
	navigate: NavigateFunction,
	courses: CourseInfo[],
	sort: SortCourseFilter,
	onChangeSort: (sortBy: string, sortDir: string) => void
}
interface SummaryState {
	courseId: number
}

export class StudentsSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		// initial state
		this.state = {
			courseId: 0
		}

		// Register the callbacks.
		this.onSelectItem = this.onSelectItem.bind(this);
		this.onViewItem = this.onViewItem.bind(this);
	}

	onSelectItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {user} = this.props;

		user.setStudentInfo(studentId, orderId, courseId);
		this.setState({courseId: this.state.courseId !== courseId ? courseId : 0});
	}

	onViewItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to the student page.
		user.setStudentInfo(studentId, orderId, courseId);
		navigate("/student");
	}

	render() {
		// Construct the list.
		let summary = {
			enrolled: 0,
			started: 0,
			completed: 0
		}
		const header = this.renderHeader();
		const body = this.renderBody(summary);
		const footer = this.renderFooter(summary);

		// render
		return (
			<div id="StudentsSummary">
				<Table id="StudentsSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		)
	}

	renderBody(summary: any) {
		// setup
		const {courses, sort} = this.props;
		const {courseId} = this.state;
		const showCategories = (sort.by === 'company_name');

		// Create the body elements.
		let category = "";
		const body = [];
		for (let i = 0; i < courses.length; i++) {
			// item
			const item = courses[i];

			// Check the category section.
			if (showCategories && item.company_name !== category) {
				category = item.company_name;
				body.push(<tr key={'course-category-'+item.id}>
					<td className="location" colSpan={9}>{category}</td>
				</tr>);
			}

			// course information
			if (item.enrolled) {
				summary.enrolled++;
			}
			if (item.started) {
				summary.started++;
			}
			if (item.completed) {
				summary.completed++;
			}

			const selected = (item.id === courseId) ? 'selectable selected' : 'selectable';
			const name = firstLastToName(item.user_first_name, item.user_last_name);
			const contact = formatContactInfo(item.email, item.phone);

			body.push(
				<tr
					key={'course-'+item.id}
					className={selected}
					onClick={() => this.onSelectItem(item.user_id, item.id, 0)}
				>
					<td className='tab'/>
					<td
						className='link'
						onClick={() => this.onViewItem(item.user_id, item.id, 0)}
					>
						{item.id}
					</td>
					<td className='student'>
						<div>{name + ` (${item.user_id})`}</div>
						<div className='sub-description'>{contact}</div>
					</td>
					{showCategories ?
						<td className='description'>{item.course_title}</td> :
						<td className='description'>
							{item.course_title}
							<div className='sub-description'>{item.company_name}</div>
						</td>
					}
					<td className='date'>{item.sEnrolled}</td>
					<td className='date'>{item.sStarted}</td>
					<td className='value'>{item.sPercent}</td>
					<td className='date'>{item.sCompleted}</td>
					<td className='value'>{item.sScore}</td>
				</tr>
			);
		}

		// Return the body.
		return body;
	}

	renderFooter(summary: any) {
		// render
		return <tr key='course-company-footer'>
			<td colSpan={4}/>
			<td className='value'>{summary.enrolled.toLocaleString()}</td>
			<td className='value'>{summary.started.toLocaleString()}</td>
			<td/>
			<td className='value'>{summary.completed.toLocaleString()}</td>
			<td/>
		</tr>;
	}

	renderHeader() {
		// setup
		const {sort, onChangeSort} = this.props;

		// render
		return [
			<tr key='course-company-header'>
				<th
					className={'tab ' + sort.company_name + (sort.by === 'company_name' ? ' active' : '')}
					onClick={() => onChangeSort('company_name', sort.company_name)}
				>
					<FaRegBuilding className='field-icon' size={9} color='#000000'/>
				</th>
				<th
					className={'value ' + sort.id + (sort.by === 'id' ? ' active' : '')}
					onClick={() => onChangeSort('id', sort.id)}
				>
					ID
				</th>
				<th
					className={'student ' + sort.user_last_name + (sort.by === 'user_last_name' ? ' active' : '')}
					onClick={() => onChangeSort('user_last_name', sort.user_last_name)}
				>
					Student
				</th>
				<th
					className={'description ' + sort.course_title + (sort.by === 'course_title' ? ' active' : '')}
					onClick={() => onChangeSort('course_title', sort.course_title)}
				>
					Course
				</th>
				<th
					className={'date ' + sort.enrolled + (sort.by === 'enrolled' ? ' active' : '')}
					onClick={() => onChangeSort('enrolled', sort.enrolled)}
				>
					Enrolled
				</th>
				<th
					className={'date ' + sort.started + (sort.by === 'started' ? ' active' : '')}
					onClick={() => onChangeSort('started', sort.started)}
				>
					Started
				</th>
				<th
					className={'date ' + sort.progress + (sort.by === 'progress' ? ' active' : '')}
					onClick={() => onChangeSort('progress', sort.progress)}
				>
					Progress
				</th>
				<th
					className={'date ' + sort.completed + (sort.by === 'completed' ? ' active' : '')}
					onClick={() => onChangeSort('completed', sort.completed)}
				>
					Finished
				</th>
				<th
					className={'value ' + sort.score + (sort.by === 'score' ? ' active' : '')}
					onClick={() => onChangeSort('score', sort.score)}
				>
					Score
				</th>
			</tr>
		];
	}

}
