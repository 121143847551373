// react
import React from "react";
import {NavigateFunction} from "react-router-dom";
import {IoReload} from "react-icons/io5";
import {FiUsers} from "react-icons/fi"

// local
import {
	Client,
	CourseInfo,
	SortCourseFilter,
	SortCourseFilterInfo,
	User
} from "../../types";
import {
	Loading,
	NavButton, NavSearch,
	TimeframeDropdown
} from "../../components";
import {
	StudentsSummary
} from "./StudentsSummary";

/**
 * StudentsSection
 */

interface SectionProps {
	user: User,
	client: Client
	navigate: NavigateFunction
}
interface SectionState {
	loaded: boolean,
	clientName: string,
	courses: CourseInfo[],
	fCourses: CourseInfo[],
	search: string,
	sort: SortCourseFilter,
	startDate: string,
	endDate: string
}

export class StudentsSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		const {client} = props;

		// filters
		const sort = SortCourseFilterInfo.create({
			id: 'sort-desc',
			company_name: 'sort-desc',
			user_last_name: 'sort-asc',
			course_title: 'sort-asc',
			enrolled: 'sort-desc',
			started: 'sort-desc',
			progress: 'sort-desc',
			completed: 'sort-desc',
			score: 'sort-desc',
			by: 'completed',
			direction: 'sort-desc'
		});

		// initial state
		this.state = {
			clientName: client.name,
			loaded: false,
			courses: [],
			fCourses: [],
			search: '',
			sort,
			startDate: client.coursesStartDate,
			endDate: client.coursesEndDate
		}

		// Register the callbacks.
		this.onChangeSort = this.onChangeSort.bind(this);
		this.onClearSearch = this.onClearSearch.bind(this);
		this.onGenerateReport = this.onGenerateReport.bind(this);
		this.onReloadList = this.onReloadList.bind(this);
		this.onSubmitTimeframe = this.onSubmitTimeframe.bind(this);
		this.onUpdateSearch = this.onUpdateSearch.bind(this);
	}

	componentDidMount = async () => {
		// setup
		await this.fetchCourses(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName, startDate, endDate} = this.state;

		// See if we need changes.
		if (pState.clientName !== client.name) {
			await this.setState({clientName: client.name});
		}
		if (pState.clientName !== clientName ||
			pState.startDate !== startDate || pState.endDate !== endDate) {
			await this.fetchCourses(true);
		}
	}

	fetchCourses = async (force: boolean) => {
		// setup
		const {client} = this.props;
		const {startDate, endDate, search, sort} = this.state;

		// Update the course information.
		const courses = await client.fetchCoursesAnalytics(client.name, startDate, endDate, force);
		let fCourses = this.filterBySortAndSearch(courses, sort.by, sort.direction, search);
		this.setState({loaded: true, clientName: client.name, courses, fCourses});
	}

	filterBySearch(courses: CourseInfo[], search: string): CourseInfo[] {
		// See if there are any matches.
		let fCourses = null;
		if (search) {
			const sSearch = search.toLowerCase();
			const iSearch = parseInt(search);
			fCourses = courses.filter(item => {
				return item.search.includes(sSearch) || (item.user_id == iSearch);
			});
		}
		return fCourses ? fCourses : courses;
	}

	filterBySort(courses: CourseInfo[], sortBy: string, sortDirection: string): CourseInfo[] {
		// Determine the sort selection.
		const f1 = sortBy;
		const f2 = (sortBy === 'id') ? 'course_title' : 'id';

		// Filter according to the sort selection.
		return courses.sort((a: any, b: any) => {
			if (a[f1] === b[f1]) {
				return a[f2] > b[f2] ? 1 : -1;
			} else if (sortDirection === 'sort-asc') {
				return a[f1] > b[f1] ? 1 : -1;
			}
			return a[f1] > b[f1] ? -1 : 1;
		});
	}

	filterBySortAndSearch(courses: CourseInfo[], sortBy: string, sortDirection: string, search: string) {
		return this.filterBySearch(this.filterBySort(courses, sortBy, sortDirection), search);
	}

	onClearSearch(event: any) {
		// setup
		const {courses, search} = this.state;
		const target = event.target.previousSibling;
		target.value = '';

		// Filter by the search value.
		if (target.value !== search) {
			const fCourses = this.filterBySearch(courses, target.value);
			this.setState({fCourses, search: target.value});
		}
	}

	onChangeSort(sortBy: string, sortDir: string) {
		// setup
		let {courses, sort, search} = this.state;

		sort.set(sortBy, sortDir);
		let fCourses = this.filterBySortAndSearch(courses, sort.by, sort.direction, search);
		this.setState({fCourses, sort});
	}

	onGenerateReport = async () => {
		// setup
		const {client} = this.props;
		const {startDate, endDate} = this.state;

		// Generate the progress report.
		await client.generateReport(client.name, 'progress', startDate, endDate);
	}

	onReloadList = async () => {
		// Refetch the list.
		this.fetchCourses(true);
	}

	onSubmitTimeframe(startDate: string, endDate: string) {
		// Update the settings.
		this.setState({startDate, endDate});
	}

	onUpdateSearch(event: any) {
		// setup
		const {courses, search} = this.state;
		const target = event.target;

		// Filter by the search value.
		if (target.value !== search) {
			const fCourses = this.filterBySearch(courses, target.value);
			this.setState({fCourses, search: target.value});
		}
	}

	render() {
		// setup
		const {loaded} = this.state;

		// render
		if (!loaded) {
			return <Loading />
		}
		return (
			<div>
				<div className='section'>
					{this.renderSectionHeader()}
					{this.renderStudentTables()}
					<hr/>
				</div>
			</div>
		)
	}

	renderSectionHeader() {
		// setup
		const {startDate, endDate} = this.state;

		// render
		return <div className='section-header'>
			<FiUsers className="section-icon" size={28}/>
			Student Progress
			<IoReload
				className='section-reload'
				data-tooltip-id="lct-reporting-tooltip"
				data-tooltip-content="Click to refresh list"
				data-tooltip-place='right'
				size={18}
				onClick={this.onReloadList}
			/>

			{ /* actions */ }
			<TimeframeDropdown
				startDate={startDate}
				endDate={endDate}
				onSubmitTimeframe={this.onSubmitTimeframe}
			/>
			<NavButton
				className='navigate-report'
				text='Generate Report'
				icon={<FiUsers className="nav-icon" size={18}/>}
				onClick={this.onGenerateReport}
			/>
			<NavSearch
				text='Search for...'
				onClearSearch={this.onClearSearch}
				onUpdateSearch={this.onUpdateSearch}
			/>
		</div>
	}

	renderStudentTables() {
		// setup
		const {user, client, navigate} = this.props;
		const {fCourses, loaded, sort} = this.state;

		// render
		if (loaded) {
			return <div className="row">
				{loaded && <StudentsSummary
					user={user}
					client={client}
					courses={fCourses}
					navigate={navigate}
					sort={sort}
					onChangeSort={this.onChangeSort}
				/>}
			</div>
		}
		return <div className="row" style={{height: 20}}>
			<Loading className='vt-center'/>
		</div>;
	}

}

